export const skills = {
    heading: "Skills",
    quote: "Build your skills, not your resume",
    techSkills: [
        "JavaScript ES6",
        "Python3",
        "Jupyter Notebook",
        "Data Structures",
        "ReactJS",
        "Redux",
        "Context API",
        "HTML",
        "CSS/LESS/SASS",
        "Material UI",
        "Agile",
        "JQuery",
        "React Hook Forms",
        "NodeJS",
        "Express",
        "SQLite3",
        "PostgreSQL",
        "Okta",
        "Authentication",
        "Security",
        "AWS Amplify",
        "Algorithms",
        "Git",
        "REST"
    ],
    industryKnowledge: [
        "Software",
        "Marketing",
        "Publicity",
        "Blogging",
        "Content Writing",
        "Creative Writing",
        "Web Content",
        "Frontend",
        "Software",
        "Backend",
        "Design",
        "Web"
    ],
    interpersonalSkills: [
        "Communication",
        "Teaching",
        "Time Management",
        "Leadership",
        "Management"
    ]
};
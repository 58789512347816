import React from 'react'

import './SkillCard.css';

export const SkillCard = ({ skill }) => {
    return (
        <div className="skill-card">
            {skill}
        </div>
    )
}
